import { useEffect, useRef } from 'react';
const $ = jQuery;

import arrows from './Const/arrows';
import { whileEvent, slideEnd, slideStart } from './Const/functions';

const Sliders = (props) => {
	const { attributes, clientId, isBackend = false, custom: { carousel = null, setCarousel }, children } = props;
	const { sliders, options, arrow, arrowStyle, indicator, animation, direction, isMouseWheel, isArrowFollowSlide, isMouseDrag } = attributes;

	// Click any content slide quick slide 
	const sliderDom = useRef();

	// setting autoplay option
	useEffect(() => {
		if (sliderDom?.current) {
			if (carousel) carousel.dispose();

			var initialize = new bootstrap.Carousel(sliderDom.current, {
				interval: options.interval,
				ride: options.ride === true ? 'carousel' : false,
				pause: options.pause === true ? 'hover' : false
			});
			setCarousel && setCarousel(initialize);
		}
	}, [options]);

	// Slide on Mousewheel
	useEffect(() => {
		if (isMouseWheel) {
			$(sliderDom?.current).bind('mousewheel', whileEvent);
		} else {
			$(sliderDom?.current).unbind('mousewheel', whileEvent);
		}
	}, [isMouseWheel]);

	// slide mouse drag 
	useEffect(() => {
		if (isMouseDrag) {
			$(sliderDom?.current).on('mousedown touchstart', slideStart);
			$(sliderDom?.current).on('mouseup touchend', slideEnd);
		}
		else {
			$(sliderDom?.current).off('mousedown touchstart', slideStart);
			$(sliderDom?.current).off('mouseup touchend', slideEnd);
		}
	}, [isMouseDrag]);

	// Return All Slider
	return <div className={`bsbCarousel slide carousel ${isMouseDrag && 'mouseDrag'}  ${animation === 'default' ? '' : animation} ${direction}`} ref={sliderDom}>
		{indicator.visibility && <>
			<div className='indicatorsWrapper'>
				<div className={`carousel-indicators ${indicator?.type} ${indicator.direction} bsbDynamicPosition ${indicator.position?.split(' ')?.join('-')}`}>
					{sliders.map((slider, index) => {
						const { img } = slider || {};

						return <button key={index} type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`} aria-current="true" aria-label={index}>
							{indicator?.type === 'image' && img?.url ? <img src={img.url} alt={img?.alt || img?.title} /> : ''}
						</button>
					})}
				</div>
			</div>
		</>
		}

		{children}

		{arrow.visibility && <>
			<div className={`bsbButtonDesign ${!isBackend && isArrowFollowSlide ? 'arrowMouseEffect' : ''}`}>
				<button className="carousel-control-prev" type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide="prev" aria-label='Carousel left arrow'>

					<div className="bsbArrowButton" dangerouslySetInnerHTML={{ __html: arrows[arrowStyle].left(arrow?.size, arrow?.color) }}>
					</div>
				</button>

				<button className="carousel-control-next" type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide="next" aria-label='Carousel right arrow'>

					<div className="bsbArrowButton" dangerouslySetInnerHTML={{ __html: arrows[arrowStyle].right(arrow?.size, arrow?.color) }}>
					</div>
				</button>
			</div>
		</>}
	</div>
}
export default Sliders;