

import { getBoxValue } from './Const/functions';
import arrows from './Const/arrows';
import { getTypoCSS, getColorsCSS } from '../../Components/utils/getCSS';
const Style = ({ attributes, clientId }) => {
	const { slideInnerGap, titleTypo, titleColor, descTypo, descColor, titleMargin, descMargin, arrow, arrowStyle, indicator, SliderOverly, height, sliderHeight, borderRadius, margin, arrowWidth, arrowHeight, arrowRadius, btnColors, btnHovColors, btnTypo, btnPadding, btnBorder, btnRadius, direction, titleAnimation, descAnimation, btnAnimation } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
	${getTypoCSS('', titleTypo)?.googleFontLink}
	${getTypoCSS('', descTypo)?.googleFontLink}
	${getTypoCSS('', btnTypo)?.googleFontLink}
	${getTypoCSS(`#bsbCarousel-${clientId} h5`, titleTypo)?.styles}
	${getTypoCSS(`#bsbCarousel-${clientId} p`, descTypo)?.styles}
	${getTypoCSS(`#bsbCarousel-${clientId} .carousel-button a`, btnTypo)?.styles}

	#bsbCarousel-${clientId} .bsbCarousel {
		margin:${getBoxValue(margin)};
	}

	#bsbCarousel-${clientId} h5{
		color: ${titleColor};
		margin: ${getBoxValue(titleMargin)};
		animation-delay: ${titleAnimation?.delay}s;
		animation-duration: ${titleAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} p {
		color: ${descColor};
		margin: ${getBoxValue(descMargin)}; 
		animation-delay: ${descAnimation?.delay}s;
		animation-duration: ${descAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} .carousel-button {
		animation-delay: ${btnAnimation?.delay}s;
		animation-duration: ${btnAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} .carousel-button a {
		${getColorsCSS(btnColors)};
		padding: ${getBoxValue(btnPadding || {})};
		border: ${getBoxValue(btnBorder || {})};
		border-radius: ${btnRadius};
		transition:0.3s;
	}

	#bsbCarousel-${clientId} .carousel-button a:hover {
		${getColorsCSS(btnHovColors)};
		transition:0.3s;
	}

	#bsbCarousel-${clientId} .carousel-item {
		position:relative;
		height: ${sliderHeight?.desktop || height};
		border-radius: ${getBoxValue(borderRadius)};
		box-sizing: border-box;
		overflow: hidden;
	}

	#bsbCarousel-${clientId} .bsbButtonDesign .bsbArrowButton {
		${getColorsCSS(arrow)};
		font-size:${arrow?.size}px;
		width:${arrowWidth};
		height:${arrowHeight};
		border-radius:${getBoxValue(arrowRadius)};
	}

	#bsbCarousel-${clientId} .default .bsbButtonDesign button{
		width:calc(40px + ${arrowWidth});
	}

	#bsbCarousel-${clientId} .carousel-item:after{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: ${SliderOverly};
	}

	#bsbCarousel-${clientId} .carousel-item .carousel-caption {
		width:calc(100% - ${slideInnerGap});
	}

	#bsbCarousel-${clientId} .carousel-indicators {
		flex-direction: ${"vertical" === indicator?.direction ? 'column' : 'row'};
	}

	#bsbCarousel-${clientId} .carousel-indicators button{
		width: ${indicator?.width} !important;
		height: ${indicator?.height} !important;
		background-color:${indicator.color};
		border: ${indicator?.border?.width || '0px'} solid transparent;
		border-radius: ${indicator?.radius};
		transform: translateY(${indicator?.moveFromEdge});
	}

	#bsbCarousel-${clientId} .carousel-indicators button.active{
		background-color:${indicator?.activeColor};
		border: ${getBoxValue(indicator?.activeBorder || {})};
	}

	#bsbCarousel-${clientId} .carousel-indicators.vertical button {
		transform: translateX(${indicator.moveFromEdge});
	}

	#bsbCarousel-${clientId} .arrowMouseEffect .carousel-control-prev:hover{
		cursor: url("data:image/svg+xml;utf8,${arrows[arrowStyle].left(arrow?.size, arrow?.color, direction)}"), default;
	}

	#bsbCarousel-${clientId} .arrowMouseEffect .carousel-control-next:hover{
		cursor: url("data:image/svg+xml;utf8,${arrows[arrowStyle].right(arrow?.size, arrow?.color, direction)}"), default;
	}

	@media (max-width: 768px) { 
		#bsbCarousel-${clientId} .carousel-item {
			height: ${sliderHeight?.tablet || sliderHeight?.desktop || height};
		}
	}

	@media (max-width: 576px) { 
		#bsbCarousel-${clientId} .carousel-item { 
			height: ${sliderHeight?.mobile || sliderHeight?.tablet || sliderHeight?.desktop || height};
		}
	}

	`.replace(/\s+/g, ' ')
	}} />
}
export default Style;